import { AbsenceModel } from '@/models/app-user-dto'
import { LayoutService } from '@/services/layout-service'
import { UserprofileService } from '@/services/user-profile-service'
import { Component, Vue } from 'vue-property-decorator'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import Alert from '@/components/shared/Alert.vue'
import { ErrorService } from '@/services/error.service'
import { abseceTypes, weekDays } from '@/views/Patients/Dossier/Constants'
import Commons from '@/components/shared/Helpers/commons'
import { AuthService } from '@/services/auth-service'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'
import { AdministrationHelper } from '../../AdministrationHelper'

@Component({
  components: {
    Confirm,
    Alert
  }
})
export default class Absences extends Vue {
  private layoutService = LayoutService.getInstance()
  private absencesService = UserprofileService.getInstance()
  private userService = AuthService.getInstance()
  public userId = ''
  public userName = ''
  public showConfirm = false
  public errorMessages: string[] = []
  public headersAbsences = [
    { text: 'Type', value: 'absencetype' },
    { text: 'Début', value: 'startDate' },
    { text: 'Fin', value: 'endDate' },
    { text: 'Heure', value: 'startHour', sortable: false },
    { text: 'Periodicité', value: 'daysRepeating', sortable: false },
    { text: '', value: 'actions', sortable: false, align: 'right' }
  ]

  public listAbsences: AbsenceModel[] = []
  public selectedAbsence?: AbsenceModel = undefined
  public absenceLoading = false
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public async mounted () {
    this.userId = this.$route.params.userId
    this.userName = (await this.userService.getUserById(this.userId)).fullName
    this.getAbsences()
    this.layoutService.updateDrawerList(AdministrationHelper.GetAdminNavItems())
  }

  private getAbsences () {
    this.absenceLoading = true
    this.absencesService.GetUserAbsences(this.userId).then((absences: AbsenceModel[]) => {
      this.listAbsences = absences
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.absenceLoading = false
    })
  }

  public editIAbsence (absence: AbsenceModel) {
    this.absencesService.pushAbsence(absence)
    const path = `/administration/absence/${this.userId}/${absence.id}`
    this.$router.push(path)
  }

  public confirmAbsenceItemDelete (absence: AbsenceModel) {
    this.showConfirm = true
    this.selectedAbsence = absence
  }

  public isInactive (item: AbsenceModel) {
    const today = new Date()
    const modelDate = new Date(item.dateRange?.to ?? '')
    return today >= modelDate
  }

  public getDaysRepeatingInfo (absence: AbsenceModel) {
    if (absence.daysRepeating && absence.period === 3) {
      const its = absence.daysRepeating.map((val) => {
        return weekDays.find(x => x.value === val)?.text.substr(0, 2)
      })
      return its.join(', ')
    }
  }

  public getHoursInfo (item: AbsenceModel) {
    // If it's "unique" we don't display the hours, hours come in the date column
    if (item.period === 1) return ''
    if (item.isAllDay) return 'Toute la journée'
    if (item.startHour) {
      if (item.endHour) {
        return `${item.startHour} - ${item.endHour}`
      }
      return item.startHour
    }
  }

  public displayDate (period: number, date: string, hours: string | undefined, allDay: boolean) {
    // If it's unique, display the date and time
    if (period === 1) {
      let h = ''
      if (!allDay) {
        if (hours) {
          h = hours
        } else {
          h = ''
        }
      }
      return `${Commons.TransformDateFormat(date)} ${h}`
    } else {
      return Commons.TransformDateFormat(date)
    }
  }

  public getTypeName (type: number) {
    if (type) {
      return abseceTypes.find(x => x.value === type)?.text
    }
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value && this.selectedAbsence) {
      this.absenceLoading = true
      const results = await this.absencesService.DeleteAbsence(this.selectedAbsence.id!)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.absenceLoading = false
        })
      if (results) {
        this.errorMessages = []
        this.getAbsences()
      }
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
