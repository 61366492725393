var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"main-container",attrs:{"fluid":""}},[_c('h2',[_vm._v(" Gestion des heures et des absences "),(_vm.userName)?_c('span',{staticClass:"title-infos"},[_vm._v("- "+_vm._s(_vm.userName))]):_vm._e()]),_c('alert',{attrs:{"value":!!_vm.errorMessages.length,"type":"error","canClose":true},on:{"close":_vm.hideAlert}},_vm._l((_vm.errorMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0),_c('confirm',{attrs:{"visible":_vm.showConfirm,"title":"Supprimer cette absence planifiée","message":"Cette action ne peut être annulée. Continuer et supprimer cette absence planifiée ?"},on:{"close":function($event){return _vm.confirmDeleteCallback($event)}}}),_c('div',{staticClass:"subsection"},[_c('h4',{staticClass:"subtitle"},[_vm._v("Absences planifiées")]),[_c('c-btn',{staticClass:"btn-red",attrs:{"loading":_vm.absenceLoading,"to":("/administration/absence/" + _vm.userId + "/new")}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-clipboard-clock-outline ")]),_c('span',[_vm._v("Ajouter une absence planifiée")])],1),_c('c-data-table',{attrs:{"headers":_vm.headersAbsences,"items":_vm.listAbsences,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll }},scopedSlots:_vm._u([{key:"item.absencetype",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeName(item.absencetype)))]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayDate(item.period, item.dateRange.from, item.startHour, item.isAllDay)))]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayDate(item.period, item.dateRange.to, item.endHour, item.isAllDay)))]}},{key:"item.startHour",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getHoursInfo(item)))]}},{key:"item.daysRepeating",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDaysRepeatingInfo(item)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{class:("table-row-actions " + (_vm.isInactive(item) ? 'inactive-row' : ''))},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){return _vm.editIAbsence(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-delete",on:{"click":function($event){return _vm.confirmAbsenceItemDelete(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])])],1)]}}],null,true)})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }